
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Action, Getter, State} from "vuex-class";
import moment from "moment-timezone";
import clonedeep from "lodash.clonedeep";
import { parsePhoneNumber } from 'libphonenumber-js';
import {IAgencyDB, IInterimDB, IMissionDB, IUserDB, IWorkerJob, LabelValue, MissionPeriod, StoreAction, TimeSlot} from "@/types";
import {capitalize, formattedAddress, getFormattedHour, sortWorkers, pluralize, formatPrice} from "@/helpers/commons";
import InterimLine from "@/components/interim/InterimLine.vue";
import {MissionStatus, ROUTES, WorkerMissionStatus} from "@/data";
import InlineField from "@/components/InlineField.vue";
import AgencyRead from "@/components/agency/AgencyRead.vue";
import AgencyPreview from "@/components/AgencyPreview.vue";
import PositionJobOffer from "@/components/ats/PositionJobOffer.vue";
import Foldable from "@/components/Mission/Foldable.vue";
import WorkerAvailableCommands from "@/components/Mission/WorkerAvailableCommands.vue";
import MissionAvailableCommands from "@/components/Mission/MissionAvailableCommands.vue";
import Tag from '@/components/Tag.vue';
import NewIcon from '@/components/NewIcon.vue';

type Position = {
    quantity: number
    name: string
    workers: Array<{
        _id: string
        fullname: string
        phone: string
        status: WorkerMissionStatus
        availableCommands: Array<string>
    }>
}

@Component({
    name: "MissionDetail",
    components: {PositionJobOffer, AgencyPreview, AgencyRead, InlineField, InterimLine, MissionAvailableCommands, Foldable, WorkerAvailableCommands, Tag, NewIcon}
})
export default class MissionDetail extends Vue {
    @Prop(Object) readonly mission!: IMissionDB;

    @State('collaborators') collaborators!: IUserDB[];
    @State('currentAgency') currentAgency!: IAgencyDB;

    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('isAgency') isAgency!: LabelValue[];
    @Getter('isCompany') isCompany!: LabelValue[];
    @Getter('hasATS') hasATS!: boolean
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('isInteraction') isInteraction!: boolean

    getFormattedHour = getFormattedHour;
    moment = moment;
    capitalize = capitalize;
    pluralize = pluralize;
    formatPrice = formatPrice;
    parsePhoneNumber = parsePhoneNumber;

    // @ts-ignore
    apiUrl= process.env.VUE_APP_API_URL.replace('/1.0', '') + "/";

    get positions() {
        return (this.mission.positions || []).map((position: any, index: number) => {
            return {
                ...position,
                name: this.getJob(position.jobId)?.label!,
                workers: (this.mission.workers?.map((worker: any) => {
                    return worker.positionIndex === index && {
                        _id: worker.workerId,
                        fullname: capitalize(worker.firstname) + " " + capitalize(worker.lastname),
                        phone: worker.phone,
                        status: worker.status,
                        contractId: worker.contractId,
                        availableCommands: worker.availableCommands,
                        presentationText: worker.presentationText && worker.presentationText[this.currentAgency._id],
                        registered: worker.registered,
                        criteria: worker.criteria,
                        picture: worker.picture,
                        distance: worker.distance,
                        reproposedTimestamp: worker.reproposedTimestamp,
                        warnings: worker.warnings,
                    };
                }) || []).filter((a:any) => a)
            }
        }) || [];
    }

    get isActiveMission() {
        return this.mission.status === MissionStatus.draft
            || this.mission.status === MissionStatus.created
            || this.mission.status === MissionStatus.presented
            || this.mission.status === MissionStatus.proposed
            || this.mission.status === MissionStatus.ready;
    }

    // get isMissionSearchMode() {
    //     return this.mode === 'missionSearch';
    // }

    // get isMissionInSearchMode() {
    //     return this.mode === 'missionInSearch';
    // }

    get hasCommands() {
        return this.isAgency;
    }

    toggle = false;

    workerPicture(worker: any) {
        return this.apiUrl + worker.picture;
    }

    positionHourlyWageAndXp(position: any) {
        const xp = this.$t(`jobExperience.${position.experience}`);
        const wage = this.formatPrice(position.hourlyWage);
        return `<span>Expérience demandée : ${xp}</span><span>${wage}/h</span>`;
    }

    positionSchedule() {
        return "";
    }

    activeWorkers(position: Position) {
        return position.workers.filter(w => {
            return w //(w.status !== WorkerMissionStatus.aborted) && (w.status !== WorkerMissionStatus.rejectedByAgency) && (w.status !== WorkerMissionStatus.rejectedByCompany) && (w.status !== WorkerMissionStatus.declined);
        }).sort(sortWorkers);
    }

    goToInterim(worker: IInterimDB) {
        if (this.$route.name !== ROUTES.APP.INTERIM) {
            this.$router.push({name: ROUTES.APP.INTERIM, params: {interimId: worker._id}});
        }
    }

    get otherWorkers() {
        const statusOrder = [
            WorkerMissionStatus.aborted,
            WorkerMissionStatus.rejectedByAgency,
            WorkerMissionStatus.rejectedByCompany,
            WorkerMissionStatus.declined
        ];
        return clonedeep(this.mission.workers || [])
            .filter((worker: any) => statusOrder.indexOf(worker.status) !== -1)
            .sort(sortWorkers);
    }

    interimAlerts(interim: any) {
        const warnings: any[] = [];

        if(interim.warnings?.workerDocuments.length > 0) {
            interim.warnings.workerDocuments.forEach((doc: any) => {
                let reason = "";

                if(doc.reason === "not-have-habilitation") {
                    reason = this.$t(`page.orderCreation.steps.selection.notHaveHabilitationRequired`, {habilitationName: doc.name}) as string
                }
                if(doc.reason === "expired") {
                    reason = this.$t(`page.orderCreation.steps.selection.habilitationExpired`, {
                        habilitationName: doc.name,
                        expirationDate: moment( doc?.expirationDate).format( this.$t('date.format') as string ) || ''}
                    ) as string
                }
                if(doc.reason === "not-have-any-medical-check-up") {
                    reason = this.$t(`page.interim.administrativeDocuments.not-have-any-medical-check-up`) as string;
                }
                if(doc.reason === "not-have-any-official-document") {
                    reason = this.$t(`page.interim.administrativeDocuments.not-have-any-official-document`) as string
                }

                warnings.push({
                    text: reason,
                    style: "error",
                    expirationDate: moment( doc?.expirationDate).format( this.$t('date.format') as string ) || '',
                })
            })
        }

        return warnings;
    }

    isProposed(worker: any) {
        return worker.status === "proposed";
    }

    isAccepted(worker: any) {
        return worker.status === "accepted";
    }

    isReproposed(worker: any) {
        return worker.status === "reproposed";
    }

    isReprosedAndLost(worker: any) {
        if (worker.reproposedTimestamp !== undefined) {
            return worker.status === "lost";
        }
    }

    isAborted(worker: any) {
        return worker.status === "aborted";
    }

    getDateReproposed(worker: any) {
        return moment(worker.reproposedTimestamp).format("DD/MM");
    }

    getJob(id: string) {
        return this.jobOptions.find((i:LabelValue) => i.value === id);
    }

    commandResult(mission: IMissionDB) {
        this.$emit("missionChanged", mission);
    }

    @Watch("mission") onMissionChanged(newMission: IMissionDB) {
        console.log("MissionDetail::missionChanged", newMission);
    }
}
