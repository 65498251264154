
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IAgencyDB, IHabilitation, IInterimDB, IMissionDB, IWorkerHabilitation, LabelValue, StoreAction} from "@/types";
import {capitalize, formattedAddress} from "@/helpers/commons";
import {Action, Getter, State} from "vuex-class";
import {ROUTES} from "@/data";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import InterimMissions from "@/components/interim/InterimMissions.vue";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import InterimAvailabilities from "@/components/interim/InterimAvailabilities.vue";
import moment from 'moment-timezone';

@Component({
    name: "InterimMiniRead",
    components: {InterimAvailabilities, InterimMissions, MissionTile, VuePdfApp}
})
export default class InterimMiniRead extends Vue {
    $refs!: {
        link:any
    }
    loading = false;
    @Prop() interimId!: string;
    @Prop() mission!: IMissionDB;
    @Prop(Boolean) map!: boolean;

    @Getter('isAgency') isAgency!: boolean;
    @State('currentAgency') currentAgency!: IAgencyDB;

    @Getter('getJob') getJob!:(jobId:string) => any;
    @Getter('getJobOption') getJobOption!:(jobId:string) => LabelValue;

    @Action('actions/loadInterimMissions') loadInterimMissions!: StoreAction;
    @Action('actions/loadInterim') loadInterim!: StoreAction;

    apiUrl:string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/" ;
    interim: IInterimDB | null = null;
    interimMissions:any = {};
    showCV = false;

    cvConfig = {
        toolbar: {
            toolbarViewerRight: false,
            toolbarViewerLeft: {
                findbar: false
            }
        },
        secondaryToolbar: false
    }

    get jobs() {
        return this.interim?.jobs?.map((job:any) => this.getJobOption(job.jobId).label).join(', ') || [];
    }

    get interimAddress() {
        return formattedAddress(this.interim?.address);
    }

    get theName() {
        return capitalize(this.interim?.firstname) + " " + capitalize(this.interim?.lastname);
    }

    get cvPath() {
        if (this.interim?.cv.path) {
            return process.env.VUE_APP_API_STATIC_URL + "/" + this.interim.cv.path;
        }
    }

    openCv() {
        this.showCV = true;
    }

    downloadCV() {
        this.$refs.link?.click();
    }

    close() {
        this.$emit('close');
    }

    goToInterim() {
        this.close();
        setTimeout(() => {
            this.$router.push({name: ROUTES.APP.INTERIM, params: {interimId: this.interim?._id || ''}});
        }, 150);
    }

    formatDocumentDate(habilitation: IWorkerHabilitation) {
        return moment(habilitation.expirationDate).format( this.$t('date.format') as string );
    }

    get workerDocuments() {
        return Object.entries(this.interim?.workerDocuments)
            .map(wd => {
                return wd;
            })
    }

    isExpiredDocument(expirationDate: number) {
        return moment( moment().valueOf() ).isAfter( moment(expirationDate ));
    }

    async init() {
        this.loading = true;
        try {
            this.interim = await this.loadInterim({interimId: this.interimId});

            if (this.mission && this.interim) {
                this.interimMissions = await this.loadInterimMissions({
                    interimId: this.interim._id,
                    missionId: this.mission._id
                });
            }

        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    getCertificationName(jobId: string, certId: string) {
        let j:any = this.getJob(jobId);

        if (j) {
            let c = (j.certifications || []).find((cert:any) => cert._id === certId);

            if (c) {
                return c.name;
            }
        }
        return "";
    }

    beforeMount() {
        this.init();
    }
}
