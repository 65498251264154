
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {IInterimDB, IJobDB, IMissionDB, IWorkerJob, LabelValue, MissionPositionFront} from "@/types";
import WorkerAvailableCommands from "@/components/Mission/WorkerAvailableCommands.vue";
import {capitalize} from "@/helpers/commons";
import InterimMiniRead from "@/components/interim/InterimMiniRead.vue";
import InterimMiniReadModal from "@/components/modal/InterimMiniReadModal.vue";
import {ROUTES} from "@/data";
import {Getter} from "vuex-class";
import Avatar from '../Avatar.vue';
import NewIcon from '../NewIcon.vue';
import Tag from '../Tag.vue';
import moment from 'moment';

@Component({
    name: "InterimLine",
    components: {InterimMiniReadModal, InterimMiniRead, WorkerAvailableCommands, Avatar, NewIcon, Tag}
})
export default class InterimLine extends Vue {
    @Prop() readonly mission!: IMissionDB;
    @Prop() readonly searchedJobs!: LabelValue[];
    @Prop() readonly interim!: any;
    @Prop() readonly mode!: string;
    @Prop(Boolean) readonly selectable!: boolean;
    @Prop() readonly selected!: boolean;
    @Prop() readonly placed!: boolean;
    @Getter('userContext') userContext!: string;

    @Getter('jobOptions') jobOptions!:LabelValue[];
    @Getter('isAgency') isAgency!:boolean;
    @Getter('isInteraction') isInteraction!: boolean

    // @ts-ignore
    apiUrl= process.env.VUE_APP_API_URL.replace('/1.0', '') + "/";
    toggle = false;
    innerSelected = false;

    @Watch('selected')
    onSelected() {
        this.innerSelected = this.selected || false;
    }

    get picture() {
        return this.apiUrl + this.interim.picture
    }

    get isMissionDetailMode() {
        return this.mode === 'missionDetail';
    }

    get isMissionDetailRejectedMode() {
        return this.mode === 'missionDetailRejected';
    }

    get isMissionDetailPage() {
        return this.isMissionDetailMode || this.isMissionDetailRejectedMode;
    }

    get isMissionSearchMode() {
        return this.mode === 'missionSearch';
    }

    get isMissionInSearchMode() {
        return this.mode === 'missionInSearch';
    }

    get isSelection() {
        return this.isMissionSearchMode || this.isMissionInSearchMode;
    }

    get hasLink() {
        return this.isMissionDetailPage;
    }

    get hasCommands() {
        return this.isAgency && !this.isMissionInSearchMode && (this.isMissionDetailPage || this.isMissionSearchMode);
    }

    get hasContract() {
        return !!this.interim.contractId;
    }

    get hasCriteria() {
        return this.interim.criteria;
    }

    get interimName() {
        if (this.isMissionDetailMode || this.isMissionSearchMode) {
            return this.interim.fullname;
        } else {
            return capitalize(this.interim.firstname) + " " + capitalize(this.interim.lastname);
        }
    }

    get subName() {
        if (this.isMissionDetailPage) {
            return this.interim.phone;
        } else {
            let jobs = [];

            if (this.searchedJobs) {
                jobs = this.searchedJobs.reduce((acc: Array<String>, job: any) => {
                    const hasJob = this.interim.jobs.find((a:any) => a.jobId === job.value);
                    if (!hasJob || acc.includes(job.label)) {
                        return acc
                    }
                    acc.push(capitalize(job.label)!);
                    return acc;
                }, []);
            } else {
                jobs = this.interim.jobs?.map((job:any) => {
                    return capitalize(this.getJob(job.jobId)?.label);
                });
            }

            return (jobs?.join(', ')) || "";
        }
    }

    get scrollOptions() {
        return {
            suppressScrollY: true
        }
    }

    get interimAlerts() {
        const warnings: any[] = [];

        if(this.interim.warnings?.workerDocuments.length > 0) {
            this.interim.warnings.workerDocuments.forEach((doc: any) => {
                let reason = "";

                if(doc.reason === "not-have-habilitation") {
                    reason = this.$t(`page.orderCreation.steps.selection.notHaveHabilitationRequired`, {habilitationName: doc.name}) as string
                }
                if(doc.reason === "expired") {
                    reason = this.$t(`page.orderCreation.steps.selection.habilitationExpired`, {
                        habilitationName: doc.name,
                        expirationDate: moment( doc?.expirationDate).format( this.$t('date.format') as string ) || ''}
                    ) as string
                }
                if(doc.reason === "not-have-any-medical-check-up") {
                    reason = this.$t(`page.interim.administrativeDocuments.not-have-any-medical-check-up`) as string;
                }
                if(doc.reason === "not-have-any-official-document") {
                    reason = this.$t(`page.interim.administrativeDocuments.not-have-any-official-document`) as string
                }

                warnings.push({
                    text: reason,
                    style: "error",
                    expirationDate: moment( doc?.expirationDate).format( this.$t('date.format') as string ) || '',
                })
            })
        }

        return warnings;
    }

    get missionExistAlerts() {
        const warnings: any[] = [];

        if(this.interim.warnings?.confirmed) {
            warnings.push({
                text: this.$t('page.orderCreation.steps.selection.warningConfirmed') as string,
                style: "neutral",
            });
        }
        if(this.interim.warnings?.proposed) {
            warnings.push({
                text: this.$t('page.orderCreation.steps.selection.warningProposed') as string,
                style: "neutral",
            });
        }
        if(this.interim.warnings?.inMission) {
            warnings.push({
                text: this.$t('page.orderCreation.steps.selection.warningInMission') as string,
                style: "neutral",
            });
        }

        return warnings;
    }

    isMissionJob(jobId:string) {
        if (this.mission) {
            return this.mission.positions?.find((position: MissionPositionFront) => {
                return position.jobId === jobId;
            });
        }
    }

    getJob(id: string) {
        return this.jobOptions.find((i:LabelValue) => i.value === id);
    }

    goToInterim() {
        if (this.$route.name !== ROUTES.APP.INTERIM) {
            this.$router.push({name: ROUTES.APP.INTERIM, params: {interimId: this.interim.workerId || this.interim._id}});
        }
    }

    emitCommand(result:any) {
        this.$emit('command', result);
    }

    openInterim() {
        this.toggle = true;
        this.$nextTick(() => {
            this.$modal.show('InterimMiniReadModal');
        })
    }

    percent(nb: number) {
        let r = nb;
        if (nb) {
             r = Math.round(nb * 100);
        }
        return r + "%";
    }

    distance(nb:number) {
        if (!nb) {
            return "";
        }
        if (nb < 1000) {
            return Math.round(nb) + "m"
        }
        return (nb / 1000).toFixed(1).replace('.', ',') + "km";
    }

    clickCheck() {
        this.innerSelected = !this.innerSelected;
        this.$emit('select', {dataId: this.interim._id, selected: this.innerSelected});
    }

    beforeMount() {
        this.interim.criteriaWeights = undefined
        this.interim.score = undefined
        this.innerSelected = this.selected || false;
    }
}
