
import {Component, Vue, Prop} from 'vue-property-decorator';
import MissionTile from "@/components/tileContent/MissionTile.vue";

@Component({
    name: "InterimMissions",
    components: {MissionTile}
})
export default class InterimMissions extends Vue {
    @Prop() interimMissions!:any;
}
