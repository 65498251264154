
import {Component, Vue, Prop} from 'vue-property-decorator';
import {StoreAction} from "@/types";

@Component({
    name: "Foldable",
    components: {}
})
export default class Foldable extends Vue {
    @Prop({ default: false }) open!: boolean;
    folded: boolean = false;

    get isOpen() {
        return !this.folded;
    }

    toggleFolded() {
        this.folded = !this.folded;
    }

    beforeMount() {
        this.folded = this.open
    }
}
