
import {Component, Vue, Prop} from 'vue-property-decorator';
import {MissionStatus, ROUTES, WorkerMissionStatus} from "@/data";
import {getFormattedHour} from "@/helpers/commons";
import moment from "moment-timezone";

@Component({
    name: "AvailabilityZone"
})
export default class AvailabilityZone extends Vue {
    @Prop() cell!: any;
    @Prop() context!: string;

    getFormattedHour = getFormattedHour;
    zoneHeight = 26.5;

    get zone() {
        return this.cell.unavailabilities?.zones && this.cell.unavailabilities?.zones[this.context];
    }

    get zones() {
        return this.cell.unavailabilities?.zones;
    }

    get isEvoliaUnavailability() {
        if(!this.context) {
            return false;
        }
        return this.cell.unavailabilities?.zones[this.context].evoliaId;
    }

    get mission() {
        if (this.zone !== undefined && this.zone !== null && this.zone?.clientName) {
            return this.zone;
        }
    }

    get hasMission() {
        return !!this.mission;
    }

    get contextIndex() {
        switch (this.context) {
            case "morning":
                return 0;
            case "afternoon":
                return 1;
            case "evening":
                return 2;
            case "night":
                return 3;
        }
    }

    get hasContext() {
        if (this.zone === false || this.zone.clientName || this.zone.value === false) return true;

        return false;
    }

    get isNextContext() {
        switch (this.context) {
            case "afternoon":
                return this.mission && this.contextHasMission('morning') //|| this.contextHasEvolia('morning');
            case "evening":
                return this.mission && this.contextHasMission('afternoon') //|| this.contextHasEvolia('afternoon');
            case "night":
                return this.mission && this.contextHasMission('evening') //|| this.contextHasEvolia('evening');
        }
    }

    get size() {
        let contexts:any = ["morning", "afternoon", "evening", "night"];
        let size = 0;
        let chaining:any = []

        // removed until evolia give other proper schedule
        // if (this.isEvoliaUnavailability) {
        //     contexts.forEach((c:string, index:number) => {
        //         if (this.contextHasEvolia(c)) {
        //             if (!chaining.length || (index && chaining.includes(index - 1))) {
        //                 size += this.zoneHeight;
        //             }
        //             chaining.push(index);
        //         }
        //     });
        // }

        if (this.mission) {
            contexts.forEach((c:string, index:number) => {
                if (this.contextHasMission(c)) {
                    if (!chaining.length || (index && chaining.includes(index - 1))) {
                        size += this.zoneHeight;
                    }
                    chaining.push(index);
                }
            });
        }

        return (size > 0 ? size : this.zoneHeight) + 'px';
    }

    get missionContext() {
        if (this.mission) {
            if (this.mission.status === MissionStatus.evaluation
                || this.mission.status === MissionStatus.terminated
                || this.mission.status === MissionStatus.archived) {
                return "black";
            }
            if (this.mission.workerStatus === WorkerMissionStatus.confirmed) {
                return "teal";
            }
            if (this.mission.workerStatus === WorkerMissionStatus.presented
                || this.mission.workerStatus === WorkerMissionStatus.validated) {
                return "purple";
            }
            if (this.mission.workerStatus === WorkerMissionStatus.proposed) {
                return "orange";
            }
            if (this.mission.workerStatus === WorkerMissionStatus.accepted) {
                return "green";
            }
            return "grey";
        }
    }

    get isOtherAgencyMission() {
        return Object.values(this.zones).some((e: any) => e.isOtherAgencyMission);
    }

    get isSameAgencyBrand() {
        //return false;
        return Object.values(this.zones).some((e: any) => e.isSameBrand);
    }

    get missionType(): {missionStatus: string, missionStatusDetail: string} {
        if(this.mission.workerStatus === WorkerMissionStatus.accepted) {
            return {
                missionStatus: this.$t('page.mission.missionStatus.accepted') as string,
                missionStatusDetail: this.$t('page.mission.missionStatus.missionStatusOnOtherAgency', {missionStatus: this.$t('page.mission.missionStatus.accepted')}) as string
            }
        }
        if(this.mission.workerStatus === WorkerMissionStatus.confirmed) {
            return {
                missionStatus: this.$t('page.mission.missionStatus.confirmed') as string,
                missionStatusDetail: this.$t('page.mission.missionStatus.missionStatusOnOtherAgency', {missionStatus: this.$t('page.mission.missionStatus.confirmed')}) as string
            }
        }
        if(this.mission.workerStatus === WorkerMissionStatus.proposed) {
            return {
                missionStatus: this.$t('page.mission.missionStatus.proposed') as string,
                missionStatusDetail: this.$t('page.mission.missionStatus.missionStatusOnOtherAgency', {missionStatus: this.$t('page.mission.missionStatus.proposed')}) as string
            }
        }

        return {
            missionStatus: '',
            missionStatusDetail: '',
        }
    }

    contextHasMission(context:string) {
        return !!(this.zones[context] && this.zones[context].clientName && this.zones[context]._id === this.mission._id);
    }

    contextHasEvolia(context: string) {
        return !!(this.zones[context] && this.zones[context].evoliaId && this.zones[context].evoliaId);
    }

    goToMission() {
        this.$router.push({name: ROUTES.APP.MISSION._ROOT, params: {missionId: this.mission._id}});
    }
}
