
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {IHeader, IInterimDB, StoreAction} from "@/types";
import {capitalize} from "@/helpers/commons";
import moment from "moment-timezone";
import {Action, Getter} from "vuex-class";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import {ROUTES} from "@/data";
import LongUnav from "@/components/modal/LongUnavailability.vue";
import {showModal} from "@/helpers/callables";
import clonedeep from "lodash.clonedeep";
import AvailabilityZone from "@/components/interim/AvailabilityZone.vue";
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";

@Component({
    name: "InterimAvailabilities",
    components: {AvailabilityZone, LongUnav, MissionTile}
})
export default class InterimAvailabilities extends mixins(TitleManager) {
    @Prop() interim !: IInterimDB;
    @Prop(String) readonly mode!:string;
    @Prop() readonly readonly!:string;

    timeout:any = 0;
    loading = false;
    unavailabilities: any = [];
    dateRange:any = {
        from: moment().startOf('month').valueOf(),
        to: moment().endOf('month').valueOf(),
    };
    globalErrors:any = [];
    innerLoading = false;
    showLongUnav = false;
    moment = moment;

    pageTitle = this.$t('page.interim.nav.availabilities');

    @Action('actions/getInterimUnavailabilities') getInterimUnavailabilities!: StoreAction;
    @Action('actions/saveLongUnavailability') saveLongUnavailability!: StoreAction;
    @Action('actions/saveUnavailability') saveUnavailability!: StoreAction;
    @Getter('serializeDate') serializeDate!:any;
    @Getter('deserializeDate') deserializeDate!:any;
    @Getter('getUnavailabilitiesReasons') getUnavailabilitiesReasons!: any;

    @Watch('dateRange', {deep: true})
    onDateRange() {
        this.timedInit();
    }

    get isEditMode() {
        return this.mode === "edit";
    }

    get headers(): IHeader[] {
        return moment.weekdays(true).map((d:string) => ({label:capitalize(d)}));
    }

    get rows() {
        const rows:any = [];

        const fromDate = moment(this.ranges.from);
        let nb = fromDate.daysInMonth();
        let i = 0;
        let rowsIndex = 0;
        let firstDay;
        let firstDateDay = fromDate.weekday();

        if (firstDateDay !== 0) {
            firstDay = fromDate.clone().subtract(firstDateDay, 'day');
            nb += firstDateDay;
        } else {
            firstDay = fromDate;
        }

        while (i < nb) {
            const cellDate:any = firstDay.clone().add(i, 'day');
            let found:any = null;

            if (!rows[rowsIndex]) {
                rows[rowsIndex] = [];
            }

            if (cellDate.month() === fromDate.month() && this.unavailabilities) {
                found = this.unavailabilities?.find((unavailability: any) => {
                    return (unavailability.date && this.isTodayOrDate(cellDate.valueOf(), unavailability.date));
                });
            }

            rows[rowsIndex].push({
                timestamp: cellDate.valueOf(),
                unavailabilities: found,
                out: firstDateDay > 0
            });

            --firstDateDay;

            if (++i % 7 === 0) {
                rowsIndex += 1;
            }

            if (i === nb && rows[rowsIndex]) {
                const rest = 7 - rows[rowsIndex].length;

                if (rest) {
                    let j = 0;
                    while (j < rest) {
                        const cellDate:any = firstDay.clone().add(i + j, 'day');
                        rows[rowsIndex].push({
                            timestamp: cellDate.valueOf(),
                            unavailabilities: null,
                            out: true
                        })
                        ++j;
                    }
                }
            }
        }

        return rows;
    }

    get ranges() {
        let fromDate, toDate;

        if (typeof this.dateRange.from === 'number') {
            fromDate = this.dateRange.from;
            toDate = this.dateRange.to;
        } else {
            fromDate = this.dateRange.from.valueOf();
            toDate = this.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate
        }
    }

    getUnavailabilityReason(cell: any) {
        const unavailability: any = Object.values(cell.unavailabilities || {}).find((zone: any) => zone.reason);
        const reason = this.getUnavailabilitiesReasons.find((unavailabilityReason: any) => unavailabilityReason.value === unavailability.reason);

        return reason.label;
    }

    getUnavailabilityComment(cell: any) {
        const unavailability: any = Object.values(cell.unavailabilities || {}).find((zone: any) => zone.comment);

        return unavailability?.comment;
    }

    subDate() {
        this.dateRange.from = moment(this.dateRange.from).subtract(1, 'month').startOf('month').valueOf();
        this.dateRange.to = moment(this.dateRange.to).subtract(1, 'month').endOf('month').valueOf();
    }

    addDate() {
        this.dateRange.from = moment(this.dateRange.from).add(1, 'month').startOf('month').valueOf();
        this.dateRange.to = moment(this.dateRange.to).add(1, 'month').endOf('month').valueOf();
    }

    isTodayOrDate(timestamp:number, d?:number) {
        const date = moment(timestamp);
        const today = moment(d);

        return date.date() === today.date()
            && date.month() === today.month()
            && date.year() === today.year()
    }

    get theRange() {
        const fromDate = moment(this.dateRange.from);

        return `<div class="month">${capitalize(fromDate.format('MMMM'))}</div><div class="year">${capitalize(fromDate.format('YYYY'))}</div>`;
    }

    isEvoliaUnavailability(cell: any) {
        return Object.values(cell.unavailabilities?.zones || {}).some((zone: any) => zone.evoliaId);
    }

    getEvoliaAvailabilityReason(cell: any) {
        const unavailability: any = Object.values(cell.unavailabilities.zones || {}).find((zone: any) => zone.reason);

        return unavailability?.reason || {};
    }

    timedInit() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 0);
    }

    hasContext(context:string, cell:any) {
        const zone = cell.unavailabilities?.zones && cell.unavailabilities?.zones[context];
        if (zone !== undefined && zone !== null) {
            if (zone === false) {
                return true;
            }
            if (zone.clientName) {
                return true;
            }
        }
        return false;
    }

    getMissionName(context:string, cell:any) {
        const zone = cell.unavailabilities?.zones && cell.unavailabilities?.zones[context];
        if (zone !== undefined && zone !== null) {
            if (zone.clientName) {
                return zone.clientName;
            }
        }
        return "";
    }

    getMissionId(context:string, cell:any) {
        const zone = cell.unavailabilities?.zones && cell.unavailabilities?.zones[context];
        if (zone !== undefined && zone !== null) {
            if (zone.clientName) {
                return zone._id;
            }
        }
        return "";
    }

    haveUnavailabilitiesReason(cell: any) {
        return cell.unavailabilities?.reason && !Object.values(cell.unavailabilities?.zones || {}).some((zone: any) => zone.evoliaId);
    }

    goToMission(missionId:string) {
        this.$router.push({name: ROUTES.APP.MISSION._ROOT, params: {missionId}});
    }

    openLongUnav() {
        showModal.call(this, 'LongUnav');
    }

    toggle(cell:any, context:string) {
        let unavailabilityValue;

        if (this.isEditMode) {
            unavailabilityValue = !cell.unavailabilities.zones[context].value;
            if (cell.unavailabilities.zones[context].evoliaId) {
                this.$set(cell.unavailabilities.zones, context, {
                    ...cell.unavailabilities.zones[context],
                    value: unavailabilityValue
                })
            } else {
                unavailabilityValue = !cell.unavailabilities.zones[context];
                this.$set(cell.unavailabilities.zones, context, unavailabilityValue);
            }

            this.$forceUpdate();
            this.updateUnav({
                date: cell.timestamp,
                ...cell.unavailabilities.zones,
                [context]: unavailabilityValue,
            });
        }
    }

    async updateUnav(data:any) {
        this.innerLoading = true;
        try {
            await this.saveUnavailability({
                interimId: this.interim?._id,
                data
            })
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async onLong(data:any) {
        this.innerLoading = true;
        try {
            const d = clonedeep(data.data);
            d.start = this.serializeDate(d.start);
            d.end = this.serializeDate(d.end);
            await this.saveLongUnavailability({
                interimId: this.interim._id,
                data: {
                    start: d.start,
                    end: d.end,
                    ...d.unavailabilities,
                    reason: d.reason,
                    comment: d.comment
                }
            });
            d.start = this.deserializeDate(d.start);
            d.end = this.deserializeDate(d.end);
            await this.$modal.hide('LongUnav');
            await this.init();
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async init() {
        this.loading = true;
        this.globalErrors = [];
        try {
            this.unavailabilities = await this.getInterimUnavailabilities({interimId: this.interim._id, ranges: this.ranges});
        } catch (e) {
            console.log(e);
            if (e?.fullErr?.response?.status === 500) {
                this.globalErrors.push({id: "Global.ServerError"});
            }
        }
        this.loading = false;
    }

    @Watch("interim")
    interimChanged() {
        this.init();
    }

    beforeMount() {
        this.init();
    }
}
