
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import DayUnavailabilities from "@/components/DayUnavailabilities.vue";
    import moment from "moment-timezone";
    import {Getter} from "vuex-class";
import { LabelValue } from '@/types';

    @Component({
        name: "LongUnav",
        components: {DayUnavailabilities}
    })
    // export default
    export default class LongUnav extends mixins(ModalMixin) {
        name = "LongUnav";

        @Getter('serializeDate') serializeDate!: any;
        @Getter('deserializeDate') deserializeDate!: any;
        @Getter('getUnavailabilitiesReasons') getUnavailabilitiesReasons!: any;

        // data
        data:any = {
            start:  moment().startOf('day').valueOf(),
            end: moment().startOf('day').add(1, 'week').valueOf(),
            unavailabilities: {
                morning: false,
                afternoon: false,
                evening: false,
                night: false,
            },
            reason: "",
            comment: ""
        };


        //computed
        get description() {
            const longUnav:any = this.$t('modals.longUnav');
            const start = moment(this.serializeDate(this.data.start)).format('LL');
            const end = moment(this.serializeDate(this.data.end)).format('LL');
            const unavs = this.data.unavailabilities;
            const avs = [
                !unavs.morning && longUnav.morning,
                !unavs.afternoon && longUnav.afternoon,
                !unavs.evening && longUnav.evening,
                !unavs.night && longUnav.night
            ].filter((a:any) => a).join(', ');

            // return `${longUnav.startDate} ${start} ${longUnav.endDate} ${end}.<br><br>${longUnav.unavailable} ${avs}.`;
            return avs && `${longUnav.unavailable} ${avs}.`;
        }

        get unavailabilitiesReasons(): LabelValue[] {
            return this.getUnavailabilitiesReasons.filter((unavailabilityReason: any) =>
                unavailabilityReason.value !== "MISSION" && unavailabilityReason.value !== "MISSION_SOUPLESSE");
        }

        //houks
        beforeMount() {
            this.data.start = this.deserializeDate(this.data.start);
            this.data.end = this.deserializeDate(this.data.end);
        }
    }
