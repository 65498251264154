
import { Component, Vue, Prop } from "vue-property-decorator";
import { JobOfferStatus, ROUTES } from "@/data";
import { IJobOfferDB, IMissionDB, StoreAction, StoreMutation } from "@/types";
import { Mutation, Action } from "vuex-class";
import { capitalize } from "@/helpers/commons";

@Component({
    name: "PositionJobOffer",
})
export default class PositionJobOffer extends Vue {
    @Prop() position!: any;
    @Prop() mission!: IMissionDB;

    jobOffer: IJobOfferDB | null = null;

    @Mutation("setMissionDataForJobOffer") setMissionDataForJobOffer!: StoreMutation;

    @Action("actions/getJobOffer") getJoboffer!: StoreAction;

    get hasJobOffer() {
        return this.position.jobOfferId;
    }

    get isActiveJobOffer() {
        return this.jobOffer?.status === JobOfferStatus.published || this.jobOffer?.status === JobOfferStatus.draft;
    }

    get jobOfferStatus() {
        if (!this.hasJobOffer) return this.$t("page.mission.jobOffer.title");
        else if (this.hasJobOffer && this.isActiveJobOffer) return this.$t("page.mission.jobOffer.inProgress");
        else if (this.hasJobOffer && !this.isActiveJobOffer) return this.$t("page.mission.jobOffer.ended");
    }

    goToJobOffer() {
        this.$router.push({ name: ROUTES.ATS.JOBOFFER.JOB, params: { jobOfferId: this.position.jobOfferId } });
    }

    createJobOffer() {
        this.setMissionDataForJobOffer({
            missionId: this.mission._id,
            missionName: capitalize(this.mission.name),
            jobId: this.position.jobId,
            jobName: capitalize(this.position.name),
            start: this.mission.periods?.length && this.mission.periods[0].start,
            name: capitalize(this.mission.name) + ": " + capitalize(this.position.name),
            description: this.mission.description,
            address: this.mission.address,
            clientId: this.mission.clientId,
            clientName: this.mission.client?.name,
            clientNameSlug: this.mission.client?.nameSlug
        });
        this.$router.push({
            name: ROUTES.ATS.JOBOFFER.ADD,
            query: {
                fromMission: "1",
            },
        });
    }

    async Init() {
        this.jobOffer = await this.getJoboffer(this.position.jobOfferId);
    }

    beforeMount() {
        try {
            if (this.hasJobOffer) {
                this.Init();
            }
        } catch (e) {
            console.error(e);
        }
    }
}
