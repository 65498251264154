import { JobOfferStatus } from "@/data";
import { BadgeProps } from "@/dsComponents/Badge";
import i18n from "@/i18n";

export default function useJobOfferStatusBadge(jobOfferStatus: JobOfferStatus): BadgeProps {
    if (jobOfferStatus === JobOfferStatus.draft) {
        return {
            color: "yellow",
            icon: "note",
            iconColor: "yellow",
            label: i18n.t("page.jobOffer.read.status.draft").toString(),
            size: "lg",
        };
    }
    if (jobOfferStatus === JobOfferStatus.unpublished) {
        return {
            color: "yellow",
            icon: "send-off",
            iconColor: "yellow",
            label: i18n.t("page.jobOffer.read.status.unpublished").toString(),
            size: "lg",
        };
    }
    if (jobOfferStatus === JobOfferStatus.published) {
        return {
            color: "green",
            icon: "circle-check",
            iconColor: "green",
            label: i18n.t("page.jobOffer.read.status.published").toString(),
            size: "lg",
        };
    }

    return {
        color: "neutral",
        icon: "",
        iconColor: "neutral",
        label: "",
        size: "lg",
    };
}
