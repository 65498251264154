
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import InterimMiniRead from "@/components/interim/InterimMiniRead.vue";
    import {IMissionDB} from "@/types";

    @Component({
        name: "InterimMiniReadModal",
        components: {InterimMiniRead}
    })
    export default class InterimMiniReadModal extends mixins(ModalMixin) {
        name = "InterimMiniReadModal";

        @Prop() interim!: any;
        @Prop() mission!: IMissionDB;
    }
